export const staticCollection = [
  {
    model_type: 'highlight',
    id: 718,
    title: '9 Мая - День Победы!',
    preamble: null,
    slug: '9-maya-den-pobedy',
    announce:
      'Подборка материалов ко Дню Великой Победы от редакции портала История.рф',
    show_in_main: false,
    published_at: '2024-05-08T12:24:52.000000Z',
    count: 45,
    likes: 1,
    views: 2597,
    has_like: false,
    has_bookmark: false,
    image: {
      model_type: 'image',
      id: 31004,
      alt: null,
      src: '/images/highlight/32/HRWnRvEFv0RbzTowIiavDhjb7vVAw3UrWUEYeP8O.jpg',
      preview:
        '/images/highlight/32/HRWnRvEFv0RbzTowIiavDhjb7vVAw3UrWUEYeP8O_min.jpg',
      original: null,
      order: 1,
      desktop:
        '/images/highlight/32/f8VWTKMtjMV9uSXsNYgedJpT91sbvVQigfQYC1yZ1715175827.jpg',
      mobile:
        '/images/highlight/32/ePyVz6GFWS2UCW7J2YILUzTvJA8hghErZAkrjf3b1715175827.jpg',
      app: '/images/highlight/32/O7uqZMEuFznFEh7EuR1O6M5udG1CmGjlQLDWewUt1715175827.jpg',
    },
    list: [
      {
        model_type: 'article',
        id: 4586,
        title: 'Главный праздник в году: как отмечали первый День Победы',
        preamble: null,
        slug: 'glavnyy-prazdnik-v-godu-kak-otmechali-pervyy-den-pobedy',
        image: {
          model_type: 'image',
          id: 7208,
          alt: null,
          src: '/images/articles/08/DnkxNkpJQJK3jb0Gxg7lNyBPMBCR50cNGUDJcZyx.jpg',
          preview:
            '/images/articles/08/DnkxNkpJQJK3jb0Gxg7lNyBPMBCR50cNGUDJcZyx_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2021-05-10T06:07:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 681,
            slug: 'barinova-oksana',
            firstname: 'Оксана',
            surname: 'Баринова',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: false,
          },
        ],
        likes: 4,
        views: 8075,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 2572,
        title: 'Краткий курс истории. «Бессмертный полк»',
        preamble: null,
        slug: 'kratkii-kurs-istorii-biessmiertnyi-polk',
        image: {
          model_type: 'image',
          id: 3680,
          alt: null,
          src: '/images/articles/04/5C4w3dKftgeYcV0W6VlHdWEAWevkEdSFvgBCOGDP.jpg',
          preview:
            '/images/articles/04/5C4w3dKftgeYcV0W6VlHdWEAWevkEdSFvgBCOGDP_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2018-05-09T00:10:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 504,
            slug: 'sukharnikov-ievghienii-sierghieievich',
            firstname: 'Евгений',
            surname: 'Сухарников',
            patronymic: '',
            image: {
              model_type: 'image',
              id: 11307,
              alt: null,
              src: '/images/author/12/EHSgrEtxioK4IEMoNidkiBEY2mcdhRjZFTnfXW6l.jpg',
              preview:
                '/images/author/12/EHSgrEtxioK4IEMoNidkiBEY2mcdhRjZFTnfXW6l_min.jpg',
              original: null,
              order: 1,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: false,
          },
        ],
        likes: 6,
        views: 11608,
        comments: 1,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 25,
        title: 'Слава нашему великому народу, народу-победителю!',
        preamble: null,
        slug: 'slava-nashiemu-vielikomu-narodu-narodu-pobieditieliu',
        image: {
          model_type: 'image',
          id: 19704,
          alt: null,
          src: '/images/articles/20/1cXE9bRkdw1yvbzeFIt1mKJeB5Bj72A3gFc6a9A8.jpg',
          preview:
            '/images/articles/20/1cXE9bRkdw1yvbzeFIt1mKJeB5Bj72A3gFc6a9A8_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '1945-05-09T00:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 51,
            slug: 'stalin-iosif-vissarionovich',
            firstname: 'Иосиф',
            surname: 'Сталин',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: '&nbsp;',
            is_subscribed: false,
          },
        ],
        likes: 3,
        views: 30764,
        comments: 1,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 4354,
        title: 'День Победы 9 мая',
        preamble: null,
        slug: 'dien-pobiedy-9-maia-event',
        image: {
          model_type: 'image',
          id: 20799,
          alt: null,
          src: '/images/articles/21/tMEXqXEtK6qlm7r3c2FyEiPtk1uGdM36Y1BVr4aE.jpg',
          preview:
            '/images/articles/21/tMEXqXEtK6qlm7r3c2FyEiPtk1uGdM36Y1BVr4aE_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2013-06-25T13:27:45.000000Z',
        authors: [],
        likes: 8,
        views: 42075,
        comments: 2,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 4585,
        title: 'День Победы: от нас далек, но нам близок',
        preamble: null,
        slug: 'den-pobedy-ot-nas-dalek-no-nam-blizok',
        image: {
          model_type: 'image',
          id: 7207,
          alt: null,
          src: '/images/articles/08/nlLvUxsK60LcMDhKJ9zpcpiWvdFeBNpIBIGVJi7Y.jpg',
          preview:
            '/images/articles/08/nlLvUxsK60LcMDhKJ9zpcpiWvdFeBNpIBIGVJi7Y_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2021-05-09T06:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 717,
            slug: 'pozhidaiev-ilia',
            firstname: 'Илья',
            surname: 'Пожидаев',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: false,
          },
        ],
        likes: 0,
        views: 5539,
        comments: 0,
        number: null,
        comment: null,
      },
    ],
    number: null,
    comment: null,
    seo: {
      model_type: 'metadata',
      metatitle: '9 Мая - День Победы!',
      metadescription:
        'Подборка материалов ко Дню Великой Победы от редакции портала История.рф',
      index: true,
      follow: true,
      archive: true,
      last_modified: '2024-11-27T11:10:33.000000Z',
    },
    content_types: [
      {
        model: 'article',
        value: ['article'],
        name: 'Статьи',
        count: 26,
      },
      {
        model: 'audiomaterial',
        value: ['audiolecture'],
        name: 'Аудио',
        count: 4,
      },
      {
        model: 'videomaterial',
        value: ['videolecture', 'film'],
        name: 'Видео',
        count: 15,
      },
      {
        model: 'test',
        value: ['test'],
        name: 'Тесты',
        count: 0,
      },
      {
        model: 'biography',
        value: ['biography'],
        name: 'Биографии',
        count: 0,
      },
      {
        model: 'wiki',
        value: ['wiki'],
        name: 'Энциклопедии',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'region_heraldic',
        value: ['region_heraldic'],
        name: 'Регионы | Символика',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_route',
        value: ['region_route'],
        name: 'Регионы | Маршруты',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_article',
        value: ['region_article'],
        name: 'Регионы | Статьи',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'illustration',
        value: ['illustration'],
        name: 'Карты и иллюстрации',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document_collection',
        value: ['document_collection'],
        name: 'Документы',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document',
        value: ['document'],
        name: 'Документы',
        count: 0,
      },
    ],
  },
  {
    model_type: 'highlight',
    id: 101,
    title:
      'Освобождение  народов Европы.  Победа СССР в Великой  Отечественной  войне.',
    preamble: null,
    slug: 'osvobozhdenie-narodov-evropy-pobeda-sssr-v-velikoy-otechestvennoy-voyne',
    announce:
      'Освобождение  народов Европы.  Победа СССР в Великой  Отечественной  войне.',
    show_in_main: false,
    published_at: '2023-10-16T20:11:52.000000Z',
    count: 18,
    likes: 0,
    views: 927,
    has_like: false,
    has_bookmark: false,
    image: {
      model_type: 'image',
      id: 26016,
      alt: null,
      src: '/images/highlight/27/nS3IgJZaDst4NVUz6CIjqP15AfIex9viTxcczX3l.jpg',
      preview:
        '/images/highlight/27/nS3IgJZaDst4NVUz6CIjqP15AfIex9viTxcczX3l_min.jpg',
      original: null,
      order: 1,
      desktop:
        '/images/highlight/27/yPjcHVAc8kJbakaPLdVHzScKyFa642jWq2x6l9Xm1697494799.jpg',
      mobile:
        '/images/highlight/27/5c4BdfXAnwrDJR29I8YgFOETNldsNAg2FM7mCiHg1697494799.jpg',
      app: '/images/highlight/27/3JsOa6nDNaOKap7zsVDodB4JsDt3mNgAHRSj1iNj1697494799.jpg',
    },
    list: [
      {
        model_type: 'lecture',
        id: 997,
        title: 'С. Горшков.  Советская кавалерия в  Венгрии.',
        preamble: null,
        slug: 's-gorshkov-sovetskaya-kavaleriya-v-vengrii',
        image: {
          model_type: 'image',
          id: 25997,
          alt: null,
          src: '/images/videomaterial/26/zQNclDBvGtTpD4NUg6rZr9tPXUEAgGDqasyBB5PR.jpg',
          preview:
            '/images/videomaterial/26/zQNclDBvGtTpD4NUg6rZr9tPXUEAgGDqasyBB5PR_min.jpg',
          original: null,
          order: 1,
          desktop:
            '/images/videomaterial/26/9PPYeNEI6LckkIsdQO8495CBbWlUagVbGX9lZzPf1697492828.jpg',
          mobile:
            '/images/videomaterial/26/HR7jtKAOrXs8O7A7i65XqJuEOFPwcclZ5xdafq4h1697492828.jpg',
          app: '/images/videomaterial/26/6gVFuSwqyRDgn1J3tJnVrVP6AaaLXJJzlyXzgmVK1697492828.jpg',
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2023-10-16T20:45:12.000000Z',
        authors: null,
        likes: 0,
        views: 704,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'lecture',
        id: 998,
        title: 'И. Кириченко.  Висло-Одерская  операция.',
        preamble: null,
        slug: 'i-kirichenko-vislo-oderskaya-operaciya',
        image: {
          model_type: 'image',
          id: 25998,
          alt: null,
          src: '/images/videomaterial/26/qLO9LqmecYyov6bPQzxM4QnsddmiFeOscVfeGVHv.jpg',
          preview:
            '/images/videomaterial/26/qLO9LqmecYyov6bPQzxM4QnsddmiFeOscVfeGVHv_min.jpg',
          original: null,
          order: 1,
          desktop:
            '/images/videomaterial/26/a1lt9Lcz5Cqk1GgDg48jzZhyCvsrago0hFuO9ky21697492917.jpg',
          mobile:
            '/images/videomaterial/26/QkbpqZpxVwoajmmyqOj0qiulvcZgcks4dcgIuyBy1697492917.jpg',
          app: '/images/videomaterial/26/SLlYn0NKFICXn1S5nUvUiWIJt86RgJ6hnQ2cbqxr1697492917.jpg',
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2023-10-16T20:47:15.000000Z',
        authors: null,
        likes: 0,
        views: 680,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'lecture',
        id: 999,
        title: 'Г. Копейкин. Штурм  фортов Познани.',
        preamble: null,
        slug: 'g-kopeykin-shturm-fortov-poznani',
        image: {
          model_type: 'image',
          id: 25999,
          alt: null,
          src: '/images/videomaterial/26/kb9PAo2VpQTXEEg8ivbjgpGovQ2ZlRX0eB36ZfQh.jpg',
          preview:
            '/images/videomaterial/26/kb9PAo2VpQTXEEg8ivbjgpGovQ2ZlRX0eB36ZfQh_min.jpg',
          original: null,
          order: 1,
          desktop:
            '/images/videomaterial/26/d1C1OR7ANeEX0gZsFpw2Q9RvIhzP2J1LoFuj9Mnh1697493014.jpg',
          mobile:
            '/images/videomaterial/26/UE2tLGakFq6SJ3Tx5KMlvrrw2mc41ZSlCACC2ida1697493014.jpg',
          app: '/images/videomaterial/26/xO4FEzyi3tD9a5vT4wLPxb8jxHtHbW2e3LyK9Grp1697493014.jpg',
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2023-10-16T20:48:43.000000Z',
        authors: null,
        likes: 0,
        views: 585,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'lecture',
        id: 1001,
        title: 'Великая  Отечественная война.  Освобождение.',
        preamble: null,
        slug: 'velikaya-otechestvennaya-voyna-osvobozhdenie',
        image: {
          model_type: 'image',
          id: 26001,
          alt: null,
          src: '/images/videomaterial/27/ll7UhV41dLAUvTcOcja68L0ulOXsutyYzeIUrd1G.jpg',
          preview:
            '/images/videomaterial/27/ll7UhV41dLAUvTcOcja68L0ulOXsutyYzeIUrd1G_min.jpg',
          original: null,
          order: 1,
          desktop:
            '/images/videomaterial/27/iod8aljJpE9DevWDpKlfYIbJxhhQplkVB0ROXxPN1697493193.jpg',
          mobile:
            '/images/videomaterial/27/58yfowoKXYAdloPe3f6554TmdRS0w0hapZUSPZrY1697493193.jpg',
          app: '/images/videomaterial/27/26nhDgoF5kXY26ifymT5pSYkAPJGdcD4EXIFrZvP1697493193.jpg',
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2023-10-16T20:51:43.000000Z',
        authors: null,
        likes: 0,
        views: 596,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'lecture',
        id: 1002,
        title:
          'Чего не должна  забывать Европа:  освобождение Красной  Армией нацистских  концлагерей в 1944- 1945 гг.',
        preamble: null,
        slug: 'chego-ne-dolzhna-zabyvat-evropa-osvobozhdenie-krasnoy-armiey-nacistskih-konclagerey-v-1944-1945-gg',
        image: {
          model_type: 'image',
          id: 26002,
          alt: null,
          src: '/images/videomaterial/27/RNDP8n1vCbN9bWF82YoDoZ6mymDQYFMben5adZh1.jpg',
          preview:
            '/images/videomaterial/27/RNDP8n1vCbN9bWF82YoDoZ6mymDQYFMben5adZh1_min.jpg',
          original: null,
          order: 1,
          desktop:
            '/images/videomaterial/27/zrT3oo4CArb0SUwMDVkcGZQBLGWcdRGi0JjL2kZl1697493330.jpg',
          mobile:
            '/images/videomaterial/27/9BvHGTKApvKtWrL3XSAWKvY5VwurrVb3Jo2sQeK61697493330.jpg',
          app: '/images/videomaterial/27/UeU2jLZWyusVbJuNEVx4WBwUnIdLnamJbLslv5iJ1697493330.jpg',
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2023-10-16T20:53:57.000000Z',
        authors: null,
        likes: 0,
        views: 602,
        comments: 0,
        number: null,
        comment: null,
      },
    ],
    number: null,
    comment: null,
    seo: {
      model_type: 'metadata',
      metatitle:
        'Освобождение  народов Европы.  Победа СССР в Великой  Отечественной  войне.',
      metadescription:
        'Освобождение  народов Европы.  Победа СССР в Великой  Отечественной  войне.',
      index: true,
      follow: true,
      archive: true,
      last_modified: '2024-11-27T11:28:01.000000Z',
    },
    content_types: [
      {
        model: 'article',
        value: ['article'],
        name: 'Статьи',
        count: 0,
      },
      {
        model: 'audiomaterial',
        value: ['audiolecture'],
        name: 'Аудио',
        count: 0,
      },
      {
        model: 'videomaterial',
        value: ['videolecture', 'film'],
        name: 'Видео',
        count: 18,
      },
      {
        model: 'test',
        value: ['test'],
        name: 'Тесты',
        count: 0,
      },
      {
        model: 'biography',
        value: ['biography'],
        name: 'Биографии',
        count: 0,
      },
      {
        model: 'wiki',
        value: ['wiki'],
        name: 'Энциклопедии',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'region_heraldic',
        value: ['region_heraldic'],
        name: 'Регионы | Символика',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_route',
        value: ['region_route'],
        name: 'Регионы | Маршруты',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_article',
        value: ['region_article'],
        name: 'Регионы | Статьи',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'illustration',
        value: ['illustration'],
        name: 'Карты и иллюстрации',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document_collection',
        value: ['document_collection'],
        name: 'Документы',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document',
        value: ['document'],
        name: 'Документы',
        count: 0,
      },
    ],
  },
  {
    priorityImage: '/imgs/banners/60fde60d-cf60-4bf2-b5c5-b8e7b5b8a713.jpg',
    outer_link: 'https://big-history.ru/',
  },
  {
    model_type: 'highlight',
    id: 901,
    title: 'Дальневосточный фронт',
    preamble: null,
    slug: 'dalnevostochnyy-front',
    announce:
      'Материалы, рассказывающие о ключевых событиях на Дальневосточном фронте Великой Отечественной войны',
    show_in_main: false,
    published_at: '2024-11-27T08:32:34.000000Z',
    count: 5,
    likes: 0,
    views: 4,
    has_like: false,
    has_bookmark: false,
    image: {
      model_type: 'image',
      id: 43106,
      alt: null,
      src: '/images/highlight/44/RpIp5y1HvbozXCL30EQPOYEGI9Kthda90t5gaen0.jpg',
      preview:
        '/images/highlight/44/RpIp5y1HvbozXCL30EQPOYEGI9Kthda90t5gaen0_min.jpg',
      original: null,
      order: 1,
      desktop:
        '/images/highlight/44/6Hbi2Ku9VVINAgAKudABJLZdBrKthjNvFkyxC3nV1732700781.jpg',
      mobile:
        '/images/highlight/44/zL9Nwy9ZYxOCsQEuWkzEDSTQxwVDxE714MzCBIZ71732700781.jpg',
      app: '/images/highlight/44/k0osDfXGrymvdoSvGyFckUWKfgwvIAQDNJ6yOY9S1732700781.jpg',
    },
    list: [
      {
        model_type: 'article',
        id: 4299,
        title: 'Маньчжурская стратегическая наступательная операция',
        preamble: null,
        slug: 'man-chzhurskaia-stratieghichieskaia-nastupatiel-naia-opieratsiia-event',
        image: {
          model_type: 'image',
          id: 20453,
          alt: null,
          src: '/images/articles/21/v2K8DRk1kzwTHhBga0H7Bd8Q1XpKwTvBqCdMwVxg.jpg',
          preview:
            '/images/articles/21/v2K8DRk1kzwTHhBga0H7Bd8Q1XpKwTvBqCdMwVxg_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2013-06-25T13:27:45.000000Z',
        authors: [],
        likes: 10,
        views: 33131,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 3774,
        title: 'Маньчжурия в годы Великой Отечественной войны',
        preamble: null,
        slug: 'manchzhuriia-v-ghody-vielikoi-otiechiestviennoi-voiny',
        image: {
          model_type: 'image',
          id: 1120,
          alt: null,
          src: '/images/articles/02/Acxth4cwRr8ArV10v7txILCblgOdMBJnLRsAkKwD.jpg',
          preview:
            '/images/articles/02/Acxth4cwRr8ArV10v7txILCblgOdMBJnLRsAkKwD_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2020-05-08T13:37:00.000000Z',
        authors: [],
        likes: 0,
        views: 5682,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 3769,
        title: 'Освобождение Корейского полуострова',
        preamble: null,
        slug: 'osvobozhdieniie-korieiskogho-poluostrova',
        image: {
          model_type: 'image',
          id: 1113,
          alt: null,
          src: '/images/articles/02/GEehn8EioPOu4KC2nb8JHd3oy3GlI93nagIqhnOV.jpg',
          preview:
            '/images/articles/02/GEehn8EioPOu4KC2nb8JHd3oy3GlI93nagIqhnOV_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2020-05-08T12:58:00.000000Z',
        authors: [],
        likes: 0,
        views: 2957,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 3290,
        title: '«Августовская буря» над Маньчжурией',
        preamble: null,
        slug: 'avghustovskaia-buria-nad-manchzhuriiei',
        image: {
          model_type: 'image',
          id: 4270,
          alt: null,
          src: '/images/articles/05/nleDBnz9ybgZ6p9iU95Prlai1z5FxQXXFTzMXss2.jpg',
          preview:
            '/images/articles/05/nleDBnz9ybgZ6p9iU95Prlai1z5FxQXXFTzMXss2_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2019-08-09T01:00:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 658,
            slug: 'antonov-sierghiei',
            firstname: 'Сергей',
            surname: 'Антонов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: false,
          },
        ],
        likes: 4,
        views: 22971,
        comments: 1,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 980,
        title:
          'Уроки недопрославленной победы. К годовщине разгрома Японии и окончания Второй мировой войны',
        preamble: null,
        slug: 'uroki-niedoproslavliennoi-pobiedy-k-71-i-ghodovshchinie-razghroma-iaponii-i-okonchaniia-vtoroi-mirovoi-voiny',
        image: {
          model_type: 'image',
          id: 3929,
          alt: null,
          src: '/images/articles/04/Nm0wyR2rf4dARl12WgtBs3Csz53xZQf1Fmm65Mlv.jpg',
          preview:
            '/images/articles/04/Nm0wyR2rf4dARl12WgtBs3Csz53xZQf1Fmm65Mlv_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2016-09-05T00:00:00.000000Z',
        authors: [],
        likes: 1,
        views: 4126,
        comments: 0,
        number: null,
        comment: null,
      },
    ],
    number: null,
    comment: null,
    seo: {
      model_type: 'metadata',
      metatitle: 'Дальневосточный фронт',
      metadescription:
        'Материалы, рассказывающие о ключевых событиях на Дальневосточном фронте Великой Отечественной войны',
      index: true,
      follow: true,
      archive: true,
      last_modified: '2024-11-27T11:32:22.000000Z',
    },
    content_types: [
      {
        model: 'article',
        value: ['article'],
        name: 'Статьи',
        count: 5,
      },
      {
        model: 'audiomaterial',
        value: ['audiolecture'],
        name: 'Аудио',
        count: 0,
      },
      {
        model: 'videomaterial',
        value: ['videolecture', 'film'],
        name: 'Видео',
        count: 0,
      },
      {
        model: 'test',
        value: ['test'],
        name: 'Тесты',
        count: 0,
      },
      {
        model: 'biography',
        value: ['biography'],
        name: 'Биографии',
        count: 0,
      },
      {
        model: 'wiki',
        value: ['wiki'],
        name: 'Энциклопедии',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'region_heraldic',
        value: ['region_heraldic'],
        name: 'Регионы | Символика',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_route',
        value: ['region_route'],
        name: 'Регионы | Маршруты',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_article',
        value: ['region_article'],
        name: 'Регионы | Статьи',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'illustration',
        value: ['illustration'],
        name: 'Карты и иллюстрации',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document_collection',
        value: ['document_collection'],
        name: 'Документы',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document',
        value: ['document'],
        name: 'Документы',
        count: 0,
      },
    ],
  },
  {
    model_type: 'highlight',
    id: 73,
    title: 'Нюрнберг',
    preamble: null,
    slug: 'nyurnberg',
    announce:
      'Нюрнбергский трибунал  - самый громкий судебный процесс XX столетия который начался  20 ноября 1945г.  На скамье подсудимых оказалась вся правящая верхушка Третьего рейха. Уже 16 октября 1946 года в Нюрнбергской тюрьме был приведен в исполнение смертный приговор над  нацистским преступникам. О том, как была поставлена фактическая точка в истории Второй Мировой войны - подробнее в нашей подборке.',
    show_in_main: false,
    published_at: '2022-12-22T18:19:03.000000Z',
    count: 6,
    likes: 0,
    views: 3107,
    has_like: false,
    has_bookmark: false,
    image: {
      model_type: 'image',
      id: 18525,
      alt: null,
      src: '/images/highlight/19/9qEunuWLaLAXbHzouKBTiJm2aOznBdlgHzTkfgKo.jpg',
      preview:
        '/images/highlight/19/9qEunuWLaLAXbHzouKBTiJm2aOznBdlgHzTkfgKo_min.jpg',
      original: null,
      order: 1,
      desktop: null,
      mobile: null,
      app: null,
    },
    list: [
      {
        model_type: 'article',
        id: 4289,
        title:
          '20 ноября 1945 года в Нюрнберге начался процесс над руководством нацистской Германии',
        preamble: null,
        slug: 'v-niurnbierghie-nachalsia-protsiess-nad-rukovodstvom-natsistskoi-giermanii-event',
        image: {
          model_type: 'image',
          id: 20194,
          alt: null,
          src: '/images/articles/21/ZHTaWtAELL71imNClcGa5JbVV4T2N40oe8zfYjD8.jpg',
          preview:
            '/images/articles/21/ZHTaWtAELL71imNClcGa5JbVV4T2N40oe8zfYjD8_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2013-06-25T13:27:45.000000Z',
        authors: [],
        likes: 0,
        views: 18060,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 4861,
        title: 'Турции не было ни в Берлине, ни в Нюрнберге',
        preamble: null,
        slug: 'turcii-ne-bylo-ni-v-berline-ni-v-nyurnberge',
        image: {
          model_type: 'image',
          id: 11740,
          alt: null,
          src: '/images/articles/12/rzK70lzGc6ywWGoXAS7gA6CoNgvqJ37dIc1ruhvB.jpg',
          preview:
            '/images/articles/12/rzK70lzGc6ywWGoXAS7gA6CoNgvqJ37dIc1ruhvB_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2021-10-19T12:19:51.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 431,
            slug: 'gasparian-armien',
            firstname: 'Армен',
            surname: 'Гаспарян',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: false,
          },
        ],
        likes: 6,
        views: 26148,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 3901,
        title:
          'О политике нацистов в отношении мирного населения СССР. Выступление Владимира Мединского на форуме «Уроки Нюрнберга»',
        preamble: null,
        slug: 'o-politikie-natsistov-v-otnoshienii-mirnogho-nasielieniia-sssr-vystuplieniie-vladimira-miedinskogho-na-forumie-uroki-niurnbiergha',
        image: {
          model_type: 'image',
          id: 1255,
          alt: null,
          src: '/images/articles/02/x3oe8CiVnfp8giVbPMLVMcB3lgXxLtb3V9Eb5rdI.jpg',
          preview:
            '/images/articles/02/x3oe8CiVnfp8giVbPMLVMcB3lgXxLtb3V9Eb5rdI_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2020-11-20T16:51:00.000000Z',
        authors: [],
        likes: 1,
        views: 3641,
        comments: 0,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 3402,
        title: 'Нюрнбергский процесс. Главный суд ХХ века в фактах и цифрах',
        preamble: null,
        slug: 'niurnbierghskii-protsiess-glavnyi-sud-khkh-vieka-v-faktakh-i-tsifrakh-1',
        image: {
          model_type: 'image',
          id: 699,
          alt: null,
          src: '/images/articles/01/LgvyJO4OIxpbpRRFroBNsPUBqbuPDNBdNlsqv59M.jpg',
          preview:
            '/images/articles/01/LgvyJO4OIxpbpRRFroBNsPUBqbuPDNBdNlsqv59M_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2020-11-20T06:30:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 657,
            slug: 'trofimov-anton',
            firstname: 'Антон',
            surname: 'Трофимов',
            patronymic: '',
            image: {
              model_type: 'image',
              id: null,
              alt: null,
              src: '/images/user/blank-avatar-new.png',
              preview: '/images/user/blank-avatar-new_min.png',
              original: null,
              order: null,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: false,
          },
        ],
        likes: 19,
        views: 201270,
        comments: 1,
        number: null,
        comment: null,
      },
      {
        model_type: 'article',
        id: 2086,
        title: 'Краткий курс истории. Японский «Нюрнберг»',
        preamble: null,
        slug: 'kratkii-kurs-istorii-iaponskii-niurnbiergh',
        image: {
          model_type: 'image',
          id: 1874,
          alt: null,
          src: '/images/articles/02/TrkTRx7oedOG7bnkSl3kfIBANBTGNzzKroPa6ROI.jpg',
          preview:
            '/images/articles/02/TrkTRx7oedOG7bnkSl3kfIBANBTGNzzKroPa6ROI_min.jpg',
          original: null,
          order: 1,
          desktop: null,
          mobile: null,
          app: null,
        },
        max_points: null,
        questions: null,
        is_reversable: null,
        published_at: '2017-11-10T00:10:00.000000Z',
        authors: [
          {
            model_type: 'author',
            id: 504,
            slug: 'sukharnikov-ievghienii-sierghieievich',
            firstname: 'Евгений',
            surname: 'Сухарников',
            patronymic: '',
            image: {
              model_type: 'image',
              id: 11307,
              alt: null,
              src: '/images/author/12/EHSgrEtxioK4IEMoNidkiBEY2mcdhRjZFTnfXW6l.jpg',
              preview:
                '/images/author/12/EHSgrEtxioK4IEMoNidkiBEY2mcdhRjZFTnfXW6l_min.jpg',
              original: null,
              order: 1,
              desktop: null,
              mobile: null,
              app: null,
            },
            announce: null,
            is_subscribed: false,
          },
        ],
        likes: 4,
        views: 25020,
        comments: 0,
        number: null,
        comment: null,
      },
    ],
    number: null,
    comment: null,
    seo: {
      model_type: 'metadata',
      metatitle: 'Нюрнберг',
      metadescription:
        'Нюрнбергский трибунал  - самый громкий судебный процесс XX столетия который начался  20 ноября 1945г.  На скамье подсудимых оказалась вся правящая верхушка Третьего рейха. Уже 16 октября 1946 года в Нюрнбергской тюрьме был приведен в исполнение смертный приговор над  нацистским преступникам. О том, как была поставлена фактическая точка в истории Второй Мировой войны - подробнее в нашей подборке.',
      index: true,
      follow: true,
      archive: true,
      last_modified: '2024-11-27T11:25:53.000000Z',
    },
    content_types: [
      {
        model: 'article',
        value: ['article'],
        name: 'Статьи',
        count: 5,
      },
      {
        model: 'audiomaterial',
        value: ['audiolecture'],
        name: 'Аудио',
        count: 0,
      },
      {
        model: 'videomaterial',
        value: ['videolecture', 'film'],
        name: 'Видео',
        count: 0,
      },
      {
        model: 'test',
        value: ['test'],
        name: 'Тесты',
        count: 0,
      },
      {
        model: 'biography',
        value: ['biography'],
        name: 'Биографии',
        count: 0,
      },
      {
        model: 'wiki',
        value: ['wiki'],
        name: 'Энциклопедии',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'region_heraldic',
        value: ['region_heraldic'],
        name: 'Регионы | Символика',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_route',
        value: ['region_route'],
        name: 'Регионы | Маршруты',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'region_article',
        value: ['region_article'],
        name: 'Регионы | Статьи',
        count: 0,
        is_teacher: false,
      },
      {
        model: 'illustration',
        value: ['illustration'],
        name: 'Карты и иллюстрации',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document_collection',
        value: ['document_collection'],
        name: 'Документы',
        count: 0,
        is_teacher: true,
      },
      {
        model: 'document',
        value: ['document'],
        name: 'Документы',
        count: 0,
      },
    ],
  },
];
